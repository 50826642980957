import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=a3e2da10"
import script from "./Sidebar.vue?vue&type=script&lang=js"
export * from "./Sidebar.vue?vue&type=script&lang=js"
import style0 from "./Sidebar.vue?vue&type=style&index=0&id=a3e2da10&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QTooltip,QBadge});qInstall(component, 'directives', {Ripple});
