var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target-avatar",style:({
		'background-image':
			(_vm.entity.img && !_vm.icons) ||
			(_vm.entity.img &&
				_vm.icons &&
				!_vm.entity.hidden &&
				!_vm.entity.transformed &&
				!_vm.entity.reminder?.surprised)
				? 'url(' + _vm.entity.img + ')'
				: '',
		color: _vm.entity.color_label ? _vm.entity.color_label : ``,
	})},[(_vm.icons && _vm.entity.hidden)?[_c('hk-icon',{staticClass:"red",class:{ 'neutral-1': _vm.entity.color_label },attrs:{"icon":"fas fa-eye-slash"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("Hidden")])]:(_vm.icons && _vm.entity.transformed)?[_c('hk-icon',{class:{ 'neutral-1': _vm.entity.color_label },attrs:{"icon":"fas fa-paw-claws"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("Transformed")])]:(_vm.icons && _vm.entity.reminders?.surprised)?[_c('hk-icon',{staticClass:"orange",attrs:{"icon":"hki hki-surprised"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("Surprised")])]:(!_vm.entity.img && _vm.entity.url)?_c('hk-compendium-image',{attrs:{"value":_vm.entity.url}}):(!_vm.entity.img)?_c('hk-icon',{attrs:{"icon":`hki-${_vm.entity.entityType === 'npc' ? 'monster' : _vm.entity.entityType}`}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }